<template>
  <div class="navbar-container main-menu-content">
    <!-- Menu Gauche -->
    <horizontal-nav-menu-items :items="navMenuItems" />
    <!-- Logo central -->
    <app-navbar-horizontal-layout-brand />
    <!-- Compte Droite -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- 
        => COMPOSANT UTILISABLE A L'AVENIR
        <search-bar />
        <cart-dropdown />
        <notification-dropdown /> 
        <dark-Toggler class="d-none d-lg-block" />
        <locale />
      -->
      <user-dropdown />
      <horizontal-nav-menu-items :items="navMenuItemsLeft" class="left" />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import store from '@/store'

import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'

import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import UserDropdown from '@/@core/layouts/components/app-navbar/components/UserDropdown.vue'

// => COMPOSANT UTILISABLE A L'AVENIR
// import Bookmarks from '@/@core/layouts/components/app-navbar/components/Bookmarks.vue'
// import navMenuItems from '@/navigation/horizontal'
// import DarkToggler from 'components/DarkToggler.vue'
// import SearchBar from '@/@core/layouts/components/app-navbar/components/SearchBar.vue'
// import CartDropdown from '@/@core/layouts/components/app-navbar/components/CartDropdown.vue'
// import NotificationDropdown from '@/@core/layouts/components/app-navbar/components/NotificationDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,

    HorizontalNavMenuItems,
    AppNavbarHorizontalLayoutBrand,
    
    Locale,
    UserDropdown
  },

  setup () {
    return {}
  },
  computed: {
    navMenuItems () {
      return [
        {
          title: 'Accueil',
          route: 'home',
          icon: 'dashboard',
          iconType: 'google',
          children: [ 
            {
              title: 'Tableau de bord Gestion',
              route: 'dashboard-gestion',
            },
            {
              title: 'Tableau de bord Analytique',
              route: 'dashboard-analytics',
            },
          ]
        },
        {
          title: 'Parc Immobilier',
          icon: 'corporate_fare',
          iconType: 'google',
          children: [ 
            {
              type:'divider',
              title: 'Gestion du parc immo',
              class:'mt-1'
            },
            { 
              title: 'Parc Immobilier',
              route: 'properties',
            },
            {
              title: 'Bailleurs',
              path: 'directory/third/bailleurs/1',
              params: { name:'bailleurs', id: 1, pageTitle:'Bailleurs', title:'Bailleurs', tips:'Liste des bailleurs' }
            }
          ]
        },
        {
          title: 'Gestion des baux',
          route: 'leases',
          icon: 'vpn_key',
          iconType: 'google',
          children: [ 
            {
              type:'divider',
              title: 'Gestion des baux',
              class:'mt-1'
            },
            {
              title: 'Baux',
              route: 'properties',
            },
            {
              title: 'Locataires',
              path: 'directory/third/locataires/2',
              params: { name:'locataires', id: 1, pageTitle:'Locataires', title:'Locataires', tips:'Liste des locataires' }
            },
          ]
        },
        {
          title: 'Gestion de la facturation',
          route: 'affairs',
          icon: 'description',
          iconType: 'google',
          children: [ 
            {
              type:'divider',
              title: 'Gestion des baux',
              class:'mt-1'
            },
            {
              title: 'Factures',
              route: 'affairs',
            },
            {
              title: 'Avoirs',
              route: 'collaborators',
            },
            {
              title: 'Encaissements',
              route: 'collaborators',
            },
          ]
        },
        {
          title: 'GED',
          route: 'home',
          icon: 'upload_file',
          iconType: 'google'
        },
        // {
        //   title: 'Planning',
        //   icon: 'CalendarIcon',
        //   children: [
        //     {
        //       type:'divider',
        //       title: store.getters.planningViewsList.length>1 ? 'Planning' : 'Planning',
        //       class:'mt-1'
        //     },
        //     ...store.getters.planningViewsList.map(e => {
        //       return {
        //         title: e.name,
        //         name: 'planning',
        //         params: { 
        //           id: e.id,
        //           title: "Planning : "+ e.name, 
        //           tips:"Planning : "+e.name
        //         } 
        //       }
        //     }),
        //     // {
        //     //   type:'divider',
        //     // },
        //     {
        //       title: 'Créer un nouveau planning',
        //       route: 'newPlanningView'
        //     },
        //   ]
        // },
        // {
        //   title: 'Annuaire',
        //   icon: 'BookIcon',
        //   children: [
        //     {
        //       type:'divider',
        //       title: 'Annuaire',
        //       class:'mt-1'
        //     },
        //     ...store.getters.companiesTypesList.map(e => {
        //       return {
        //         title: e.label,
        //         path: 'directory/companies/' + e.label.toLowerCase() + '/' + e.id,
        //         params: { name:e.label.toLowerCase(), id: e.id, pageTitle:e.label, title:e.label, tips:'Liste des '+e.label+'s' }
        //       }
        //     }),
        //     {
        //         type:'divider',
        //         title: '',
        //     },
        //     {
        //         title: 'Contacts',
        //         route: 'contacts'
        //     }
        //   ]
        // },
        // {
        //   title: 'Entreprise',
        //   icon: 'UsersIcon',
        //   children: [ 
        //     {
        //       type:'divider',
        //       title: 'Entreprise',
        //       class:'mt-1'
        //     },
        //     {
        //       title: 'Collaborateurs',
        //       route: 'collaborators',
        //     },
        //   ]
        // }
      ]
    },
    navMenuItemsLeft () {
      return [
        {
          title: 'Admin',
          icon: 'LockIcon',
          role:'admin',
          children: [
            {
              type:'divider',
              title: 'Administration',
              class:'mt-1'
            },
            {
              title: 'Espaces de travail',
              route: 'adminWorkspaces',
              role:'admin',
            },
          ]
        },
        {
          title: 'Paramètres',
          icon: 'SettingsIcon',
          children: [
            {
              type:'divider',
              title: 'Baux',
              class:'mt-1'
            },
            {
              title: 'Périodicités',
              route: 'periodicities'
            },
            {
              title: 'Types de baux',
              route: 'lease-types'
            },
            {
              title: 'Types de biens',
              route: 'property-types'
            },
            // {
            //   type:'divider',
            //   title: 'Planning',
            //   class:'mt-1'
            // },
            // {
            //   title: 'Vues de planning',
            //   route: 'planningViews'
            // },
            // {
            //   title: "Types d'évènements",
            //   route: 'eventTypes'
            // },
            // {
            //   type:'divider',
            //   title: 'Activité',
            //   class:'mt-1'
            // },
            // {
            //   title: 'Métiers',
            //   route: 'jobs'
            // },
            // {
            //   title: 'Contrats',
            //   route: 'contracts'
            // },
            // {
            //   type:'divider',
            //   title: 'Services',
            //   class:'mt-1'
            // },
            // {
            //   title: 'Historique des SMS',
            //   route: 'sms'
            // },
          ]
        }
      ]
    }
  }
}
</script>
<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/horizontal-menu.scss';
</style>
