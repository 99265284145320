<template>
  <div class="navbar-header d-md-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
            <b-img
              :src="appLogoFull"
              alt="logo"
            />
          </span>
          <!-- <span class="menu-title badge badge-env font-smaller bg-warning">TEST</span>  -->
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoFull } = $themeConfig.app
    return {
      appName,
      appLogoFull,
    }
  },
}
</script>

<style>

</style>
